import { makeAPIRequest } from "./makeRequest";

export const GetHtmlFiles = () => {
    let config = {
        mehod: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/GetHTMLContent',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}