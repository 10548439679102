import React, { useState, useEffect, useCallback } from 'react'
import './styles.css'
import GetModal from '../api/getModal';
import { AlertsData } from './dashboard';
import { clearAllQRScans, TestQR } from '../api/testVehicleQR';

interface Props {
    onAlertChnage: AlertsData
}

interface DealershipData {
    email: string;
    address: string
    appConnect: string
    dealerId: number
    dealerShipName: string
    dmsType: string
    enable: boolean
    enableGPS: boolean
    manualRefresh: boolean
    name: string | null
    newVersion: boolean
    phoneNo: string
    piMode: boolean
}

const EnableQR = ({ onAlertChnage }: Props) => {
    const [url, setUrl] = useState('');
    const [incomingUrl, setIncomingUrl] = useState('');
    const [enableState, setEnable] = useState(false);
    const [dealership, setDealership] = useState<DealershipData | null>();
    const [id, setId] = useState<number>();
    const [name, setName] = useState('');
    let Did = sessionStorage.getItem('dealerId')
    let dealershipData = localStorage.getItem('dealershipData');
    const fetchData = useCallback(async () => {
        if (Did) {
            let res = await GetModal('GET', 'DealerShip/DMSCreate?dealerIdSel=' + Did + '-EnableQRCode', '')
            setEnable(res.enableQRCode);
            setUrl(res.url);
            setIncomingUrl(res.url);
            setId(res.id)
            setName(res.name)
        }
    }, [Did])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const toggleEnable = () => {
        setEnable(!enableState);
    }

    const sendData = async () => {
        if (url === "") {
            sendResponse(1, "Please enter the url", 'Failure')
        } else {
            let res: any;
            let data: any
            if (dealershipData) {
                data = {
                    "enableQRCode": enableState,
                    "url": url,
                    "dealership": JSON.parse(dealershipData),
                    "id": id,
                    "name": name
                }
            }
            if (incomingUrl === "") {
                res = await GetModal('POST', 'DealerShip/QRCodePost?dealerIdSel=' + Did + '-EnableQRCode', data)
                if (res === "Success") {
                    sendResponse(0, 'QRCode has been created successfully', 'Success')
                } else {
                    sendResponse(1, 'Failed to create the QRCode', 'Failure')
                }
            } else {
                res = await GetModal('POST', 'DealerShip/QRCodeEdit?dealerIdSel=' + Did + '-EnableQRCode', data)
                if (res === "Success") {
                    sendResponse(0, 'QrCode successfully edited', 'Success')
                } else {
                    sendResponse(1, 'Failed to edit the QRCode', 'Failure')
                }
            }
        }
    }

    const testURL = async () => {
        if (dealershipData) {
            let dealerId = JSON.parse(dealershipData)
            let res = await TestQR(dealerId.dealerId, url);
            if(res) {
                window.open(res, "_blank");
            }
        }
    }

    const clearQRScan = async () => {
        if(dealershipData) {
            let dealerId = JSON.parse(dealershipData);
            let res = await clearAllQRScans(dealerId.dealerId);
            if (res === "Success") {
                sendResponse(0, 'QrCode scans successfully cleared', 'Success')
            } else {
                sendResponse(1, 'Failed to clear the QRCode scans', 'Failure')
            }
        }
    }

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    return (
        <div>
            <div className="modaltop-body mt-3">
                <hr />
            </div>
            <div className="modal-body">
                <div className="flex justify-between items-center mt-3">
                    <p className="sampleLabel">Enable QRCode</p>
                    <input type='checkbox' checked={enableState} onChange={toggleEnable} />
                </div>
                <div className="flex justify-between items-center mt-3">
                    <p className="sampleLabel">Url</p>
                    <input className="textInput" type='url' placeholder='https://testSite.com/testPage1' value={url} onChange={(e) => { setUrl(e.target.value) }} />
                </div>
                <div className='mt-3 flex justify-around items-center'>
                    <button className="button-6" onClick={clearQRScan}>Clear All QR Scans</button>
                </div>
            </div>
            <div className='modal-footer'>
                <div className="flex justify-around items-center mt-4">
                    <button className="button-6" onClick={testURL}>Test</button>
                    <button className="button-6" onClick={sendData}>Save</button>
                    {/* <button className="button-6">Cancel</button> */}
                </div>
            </div>
        </div>
    )
}

export default EnableQR