import React, { useState } from "react";
import './styles.css';
import { UpdateLotLocation } from "../api/updateLocation";
import { AlertsData } from "./dashboard";

interface Props {
    onAlertChnage: AlertsData
}

const LotLocationChange = ({ onAlertChnage }: Props) => {
    const [lotLocation, setLotLocation] = useState<string>('');
    const [askLocation, setAskLocation] = useState<boolean>(false);
    let vin = sessionStorage.getItem('vin');
    let stock = sessionStorage.getItem('stockNo');
    let Did = sessionStorage.getItem('dealerId');
    let locations = localStorage.getItem('lotLocations');
    const locationOptions = locations && locations.split(",")

    const handleLotLocationChange = (e: any) => {
        if (e.target.value === 'New Location') {
            setAskLocation(true)
        } else {
            setAskLocation(false)
            setLotLocation(e.target.value)
        }
    }

    const updateLocation = async () => {
        if (Did && vin && stock) {
            let res = await UpdateLotLocation(vin, stock, parseInt(Did), lotLocation);
            if(res === "\"Success\"") {
                window.location.reload();
            } else {
                sendResponse(1,'Failure in updating the location','Failure');
            }
        } else {
            sendResponse(1,'Something went wrong please check again after sometime','Failure' );
        }
    }

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    return (
        <>
            <div className="flex flex-col justify-center items-center w-100 p-5">
                <p className="sampleLabel text-coal pb-4">Select the Lot Location</p>
                <select className="text-coal sampleLabel" onChange={handleLotLocationChange}>
                    <option>--Select--</option>
                    {locationOptions && locationOptions.map(location => (
                        <option key={location} value={location}>
                            {location}
                        </option>
                    ))}
                    <option value="New Location">New Location</option>
                </select>
                {askLocation ? (
                    <div className="flex flex-row justify-between items-center pt-4">
                        <p className="sampleLabel text-coal">Enter the prefered lot location</p>
                        <input type="text" placeholder="lot location" onChange={(e) => setLotLocation(e.target.value)} />
                    </div>
                ) : null}
            </div>
            <div>
                <button className="button-6" onClick={updateLocation}>Update Location</button>
            </div>
        </>
    )
}

export default LotLocationChange