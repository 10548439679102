import React, { useEffect } from "react";
import useToken from '../hooks/useToken';

const events = [
    "load", "mousemove", "mousedown", "click", "scroll", "keypress"
];

const AutoLogout = ({ children }: any) => {
    let timer: any;
    const { logOut } = useToken();

    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleTimer();
            });
        });
    }, []);

    const resetTimer = () => {
        if (timer) clearTimeout(timer)
    }

    const handleTimer = () => {
        timer = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            logoutAction();
        }, 900000)
        // actually use 15 mins = 900000ms
        // testing use 10000ms = 10secs 
    }

    const logoutAction = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.pathname = '/';
        logOut();
    }

    return children;
}

export default AutoLogout