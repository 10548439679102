import { configureStore, createSlice } from "@reduxjs/toolkit";

export interface GridDataState {
    gridData: any;
    lotLocation: any;
    vinNotes: any;
}

const initialState: GridDataState = {
    gridData: null,
    lotLocation: null,
    vinNotes: null
};

const gridDataSlice = createSlice({
    name: 'gridData',
    initialState,
    reducers: {
        setGridData(state, action) {
            state.gridData = action.payload;
        },
        clearGridData(state) {
            state.gridData = null;
        },
        setLotLocation(state, action) { 
            state.lotLocation = action.payload;
        },
        clearLotLocation(state) { 
            state.lotLocation = null;
        },
        setVinNotes(state, action) { 
            state.vinNotes = action.payload;
        },
        clearVinNotes(state) { 
            state.vinNotes = null;
        }
    }
});

export const Store = configureStore({
    reducer: {
        gridData: gridDataSlice.reducer
    }
});

export const { setGridData, clearGridData, setLotLocation, clearLotLocation, setVinNotes, clearVinNotes } = gridDataSlice.actions;