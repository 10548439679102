import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, Volume2, VolumeX, Maximize, Minimize, X } from 'lucide-react';
import { useNavigate } from "react-router-dom";

interface VideoPlayerProps {
    className?: string;
    onTimeUpdate?: (currentTime: number) => void;
    onDurationChange?: (duration: number) => void;
    onPlay?: () => void;
    onPause?: () => void;
}

interface ControlsTimeoutRef {
    current: number | null;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    className = '',
    onTimeUpdate,
    onDurationChange,
    onPlay,
    onPause,
}) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [showControls, setShowControls] = useState<boolean>(true);

    const videoRef = useRef<HTMLVideoElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const controlsTimeout = useRef<number | null>(null);
    const nav = useNavigate();

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const handleLoadedMetadata = () => {
            setDuration(video.duration);
            onDurationChange?.(video.duration);
        };

        const handleTimeUpdate = () => {
            setCurrentTime(video.currentTime);
            setProgress((video.currentTime / video.duration) * 100);
            onTimeUpdate?.(video.currentTime);
        };

        video.addEventListener('loadedmetadata', handleLoadedMetadata);
        video.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
            video.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [onDurationChange, onTimeUpdate]);

    const togglePlay = (): void => {
        if (!videoRef.current) return;

        if (videoRef.current.paused) {
            videoRef.current.play()
                .then(() => {
                    setIsPlaying(true);
                    onPlay?.();
                })
                .catch((error) => {
                    console.error('Error playing video:', error);
                });
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
            onPause?.();
        }
    };

    const toggleMute = (): void => {
        if (!videoRef.current) return;
        videoRef.current.muted = !videoRef.current.muted;
        setIsMuted(!isMuted);
    };

    const handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!videoRef.current) return;
        const time = (parseInt(e.target.value) / 100) * duration;
        videoRef.current.currentTime = time;
        setProgress(parseInt(e.target.value));
    };

    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const toggleFullscreen = async (): Promise<void> => {
        if (!containerRef.current) return;

        try {
            if (!document.fullscreenElement) {
                await containerRef.current.requestFullscreen();
                setIsFullscreen(true);
            } else {
                await document.exitFullscreen();
                setIsFullscreen(false);
            }
        } catch (error) {
            console.error('Error toggling fullscreen:', error);
        }
    };

    const handleMouseMove = (): void => {
        setShowControls(true);
        if (controlsTimeout.current) {
            window.clearTimeout(controlsTimeout.current);
        }
        controlsTimeout.current = window.setTimeout(() => {
            if (isPlaying) {
                setShowControls(false);
            }
        }, 3000);
    };

    const handlePlayerClose = () => {
        const referrer = document.referrer;
        // Check if the previous page was from the /help route
        if (referrer.includes('/help')) {
            nav('/dashboard');
        } else {
            window.close();
        }
    }

    return (
        <div className="flex dashboardContainer flex-col justify-center items-center resize-none rounded-md">
            <div
                ref={containerRef}
                className={`relative w-full max-w-5xl mx-auto bg-black rounded-lg overflow-hidden group justify-center item-center ${className}`}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => isPlaying && setShowControls(false)}
            >
                <div className="flex justify-center items-center">
                    <video
                        ref={videoRef}
                        className="w-full h-full"
                        src="https://www.ssivehiclewiz.com/VehWizAPI/StreamVideo"
                        onClick={togglePlay}
                        autoPlay
                    />
                </div>

                <div
                    className={`absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4 transition-opacity duration-300 ${showControls ? 'opacity-100' : 'opacity-0'}`}
                >
                    {/* Progress Bar */}
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={progress}
                        onChange={handleProgressChange}
                        className="w-full h-1 bg-gray-400 rounded-lg appearance-none cursor-pointer"
                    />

                    <div className="flex items-center justify-between mt-2">
                        <div className="flex items-center space-x-4">
                            {/* Play/Pause Button */}
                            <button
                                onClick={togglePlay}
                                className="text-white hover:text-blue-400 transition-colors"
                                type="button"
                                aria-label={isPlaying ? 'Pause' : 'Play'}
                            >
                                {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                            </button>

                            {/* Volume Button */}
                            <button
                                onClick={toggleMute}
                                className="text-white hover:text-blue-400 transition-colors"
                                type="button"
                                aria-label={isMuted ? 'Unmute' : 'Mute'}
                            >
                                {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
                            </button>

                            {/* Time Display */}
                            <span className="text-white text-sm">
                                {formatTime(currentTime)} / {formatTime(duration)}
                            </span>
                        </div>

                        {/* Fullscreen Button */}
                        <button
                            onClick={toggleFullscreen}
                            className="text-white hover:text-blue-400 transition-colors"
                            type="button"
                            aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
                        >
                            {isFullscreen ? <Minimize size={24} /> : <Maximize size={24} />}
                        </button>
                    </div>
                </div>
            </div>
            <div className='pt-5'>
                <button className='button-6' onClick={handlePlayerClose}>
                        <X size={18} />
                        <span className='pl-3'>Close Tab</span>
                </button>
            </div>
        </div>
    );
};

export default VideoPlayer;