import React, { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeedbackAPI } from '../api/feedbackApi';
import Alert from '../components/alert';
import { Star } from 'lucide-react';

interface FeedbackData {
    dealerId: number;
    userName: string;
    rating: number;
    comment: string;
    followUp: boolean;
}

const FeedbackInterface: React.FC = () => {
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);
    const [comment, setComment] = useState<string>('');
    const [followUp, setFollowUp] = useState<boolean>(false);
    const [errorCode, setErrorCode] = useState<number>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [msgVisible, setMsgVisible] = useState<boolean>(false);
    const nav = useNavigate();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let userName = sessionStorage.getItem('userName');
        let dealerId = sessionStorage.getItem('dealerId');
        const feedbackData: FeedbackData = {
            rating,
            comment,
            followUp,
            userName: '',
            dealerId: 0
        };

        if (userName && dealerId) {
            feedbackData.userName = userName;
            feedbackData.dealerId = parseInt(dealerId);

            let res = await FeedbackAPI(feedbackData);
            if (res === "Success") {
                setErrorCode(0);
                setErrorMessage('Feedback recorded successfully');
                setMsgVisible(true);
            } else {
                setErrorCode(1);
                setErrorMessage('Problem occurred while recording feedback');
            }
        }
    };

    const handleCancelAction = () => {
        nav("/dashboard");
    };

    const getCommentQuestion = () => {
        if (rating === 0) return "Do you have any thoughts you'd like to share?";
        if (rating <= 2) return "We're sorry to hear that. Could you tell us what went wrong?";
        if (rating === 3) return "What could we do to improve your experience?";
        return "We're glad you enjoyed it! What did you like most about the product?";
    };

    const getStarColor = (index: number): string => {
        const ratingValue = index + 1;
        if (ratingValue <= (hover || rating)) {
            switch(ratingValue) {
                case 1: return 'text-[#FF9529] fill-[#FF9529]'; // Deep Saffron
                case 2: return 'text-[#FFA82E] fill-[#FFA82E]'; // Blend of Deep Saffron and Metallic Yellow
                case 3: return 'text-[#FFD55A] fill-[#FFD55A]'; // Blend of Metallic Yellow and Golden Yellow
                case 4: return 'text-[#FDCC0D] fill-[#FDCC0D]'; // Metallic Yellow
                case 5: return 'text-[#FFDF00] fill-[#FFDF00]'; // Golden Yellow
            }
        }
        return 'text-gray-300';
    };

    return (
        <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md flex flex-col justify-center items-center">
            <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Feedback</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <p className="text-lg mb-2 text-gray-700">How satisfied are you with our Product?</p>
                    <div className="flex justify-center">
                        {[...Array(5)].map((_, index) => (
                            <Star
                                key={index}
                                className={`cursor-pointer transition-colors duration-200 ${getStarColor(index)}`}
                                onMouseEnter={() => setHover(index + 1)}
                                onMouseLeave={() => setHover(0)}
                                onClick={() => setRating(index + 1)}
                                size={32}
                            />
                        ))}
                    </div>
                </div>

                <div className="mb-6">
                    <p className="text-lg mb-2 text-gray-700">{getCommentQuestion()}</p>
                    <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Please share your experience with specific features..."
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        rows={4}
                    />
                </div>

                {msgVisible && (
                    <div className="mb-6">
                        <p>Thank you for your valuable feedback 😍</p>
                    </div>
                )}

                {!msgVisible ? (
                    <div className="flex justify-between">
                        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
                            Submit Feedback
                        </button>
                        <button type="button" className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500" onClick={handleCancelAction}>
                            Cancel
                        </button>
                    </div>
                ) : (
                    <button type="button" className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500" onClick={handleCancelAction}>
                        Go to Dashboard
                    </button>
                )}
            </form>
            {(errorCode === 0 || errorCode === 1) && (
                <div className="absolute top-0 right-0">
                    <Alert code={errorCode} message={errorMessage} />
                </div>
            )}
        </div>
    );
};

export default FeedbackInterface;