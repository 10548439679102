import React, { useState, useEffect, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { GetAccessoriesFrom, GETMSRPApi } from "../api/accessoryAPI";
import { GridDataState } from "../utils/store";
import { GridAlertsData } from "../components/igniteGrid";

interface Props {
    onClose: () => void;
    onAlertChnage: GridAlertsData;
}

const AddendumVehicleForm = ({ onClose, onAlertChnage }: Props) => {
    const initialGridData = useSelector((state: GridDataState) => state.gridData.gridData);
    const [selectedNames, setSelectedNames] = useState<string[]>([]);
    const [modelName, setModelName] = useState<string>(() => sessionStorage.getItem('modelNumber') || '');
    const [stockNumber, setStockNumber] = useState<string>(() => sessionStorage.getItem('stockNumber') || '');
    const [vinNumber, setVinNumber] = useState<string>(() => sessionStorage.getItem('vinNumber') || '');
    const [fileName, setFileName] = useState<string>(() => sessionStorage.getItem('printFileName') || '');
    const [freight, setFreight] = useState<any>(() => localStorage.getItem('freight') || 0.00);
    const [msrp, setMsrp] = useState<any>(() => localStorage.getItem('msrp') || '');
    const [msrpAdj, setMsrpAdj] = useState<any>(0.00);
    const [finalMSRP, setFinalMSRP] = useState<any>(localStorage.getItem('msrp') || 0.00);
    const [msrpOptions, setMsrpOptions] = useState<number[]>([]);

    useEffect(() => {
        const fetchMSRP = async () => {
            let res = await GETMSRPApi(vinNumber)
            setMsrpOptions(res);
            const savedMsrp = localStorage.getItem('msrp');
            if (savedMsrp && res.includes(Number(savedMsrp))) {
                setMsrp(savedMsrp);
            } else if (res.length > 0) {
                setMsrp(res.toString());
            }
        }

        fetchMSRP()
    }, [vinNumber])

    useEffect(() => {
        const baseMSRP = parseFloat(msrp) || 0;
        const adjustment = parseFloat(msrpAdj) || 0;
        const calculatedFinalMSRP = baseMSRP + adjustment;
        setFinalMSRP(calculatedFinalMSRP);
    }, [msrp, msrpAdj]);

    const handleCheckboxChange = (name: string) => {
        setSelectedNames(prev =>
            prev.includes(name)
                ? prev.filter(n => n !== name)
                : [...prev, name]
        );
    };

    const handleSaveForm = async () => {
        if (selectedNames.length > 0) {
            let selectedNamesString = selectedNames.join(',');
            localStorage.setItem('msrp', msrp);
            localStorage.setItem('freight', freight);
            if (vinNumber && stockNumber && modelName) {
                let addendumResult = await GetAccessoriesFrom(selectedNamesString, vinNumber, stockNumber, modelName, freight, finalMSRP, fileName)
                if (addendumResult) {
                    const blob = new Blob([addendumResult], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    iframe.src = url;
                    document.body.appendChild(iframe);
                    iframe.onload = function () {
                        iframe.contentWindow?.print();
                    };
                }
                onClose();
            }
        } else {
            sendResponse(1, 'Select at least one accessory to print', 'Failure')
        }
    }

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    const handleMSPRChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMsrp(parseFloat(e.target.value));
    }

    return (
        <div className="mx-auto bg-white shadow-md rounded px-4 pt-6 pb-6 mb-4">
            <h2 className="text-lg font-semibold mb-4 text-center text-gray-800">Select Accessories to Install</h2>

            <div className="mb-2">
                <label htmlFor="vin" className="block text-gray-700 text-sm font-bold mb-2">VIN:</label>
                <input
                    id="vin"
                    placeholder="Vehicle VIN Number"
                    disabled
                    value={vinNumber}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 text-sm"
                />
            </div>

            <div className="flex mb-4 space-x-4">
                <div className="flex-1">
                    <label htmlFor="stock" className="block text-gray-700 text-sm font-bold mb-2">Stock No:</label>
                    <input
                        id="stock"
                        placeholder="Vehicle Stock Number"
                        disabled
                        value={stockNumber}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 text-sm"
                    />
                </div>
                <div className="flex-1">
                    <label htmlFor="model" className="block text-gray-700 text-sm font-bold mb-2">Model:</label>
                    <input
                        id="model"
                        placeholder="Vehicle Model Name"
                        disabled
                        value={modelName}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 text-sm"
                    />
                </div>
            </div>

            <div className="flex mb-4 space-x-4 items-center">
                <div className="flex-1 relative">
                    <label htmlFor="freight" className="block text-gray-700 text-sm font-bold mb-2">Freight:</label>
                    <div className="relative">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">$</span>
                        <input
                            type="number"
                            id="freight"
                            placeholder="Vehicle Freight"
                            value={freight}
                            onChange={(e) => setFreight(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 pl-6 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 text-sm"
                        />
                    </div>
                </div>
                <div className="flex-1">
                    <label htmlFor="msrp" className="block text-gray-700 text-sm font-bold mb-2">Base MSRP:</label>
                    <div className="relative">
                        <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">$</span>
                        <input type="number"
                            id="msrp"
                            placeholder="Vehicle MSRP"
                            value={msrp}
                            onChange={handleMSPRChange}
                            className="shadow appearance-none border rounded w-full py-2 pl-6 pr-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 text-sm" />
                    </div>
                </div>
            </div>

            <div className="flex mb-4 space-x-4 items-center">
                <label className="block text-gray-700 text-sm font-bold mb-2">Final MSRP before adding accessories : $</label>
                <label className="block text-gray-700 text-sm font-bold mb-2">{finalMSRP}</label>
            </div>

            <div className="mb-6">
                <h3 className="block text-gray-700 text-sm font-bold mb-2">Accessories:</h3>
                {initialGridData.map((item: { name: string }, index: number) => (
                    <div key={index} className="flex items-center mb-2">
                        <input
                            type="checkbox"
                            id={`checkbox-${index}`}
                            checked={selectedNames.includes(item.name)}
                            onChange={() => handleCheckboxChange(item.name)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                        <label htmlFor={`checkbox-${index}`} className="ml-2 text-gray-700 text-sm">{item.name}</label>
                    </div>
                ))}
            </div>

            <div className="flex items-center justify-center">
                <button
                    className="button-6"
                    onClick={handleSaveForm}
                >
                    Save and Print Form
                </button>
            </div>
        </div>
    )
}

export default AddendumVehicleForm;