import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import ChangePassword from "../pages/changePassword";
import TemplateList from "../pages/templatesList";
import ScheduleReports from "../pages/scheduleReports";
import FeedbackInterface from "../pages/feedback";
import CSVFileBased from "../pages/csvFileBased";
import VideoPlayer from "../pages/videoPlayer";
import HelpPage from "../pages/help";

export const homeRoutes = (
    <>
        <Route index element={<Dashboard />} />
        <Route path="/dashboard/passwordChange" element={<ChangePassword />} />
        <Route path="/dashboard/templateList" element={<TemplateList />} />
        <Route path="/dashboard/scheduleReport" element={<ScheduleReports />} />
        <Route path="/dashboard/feedback" element={<FeedbackInterface />} />
        <Route path="/dashboard/filebasedDMS" element={<CSVFileBased />} />
        <Route path="/dashboard/demoVideo" element={<VideoPlayer />} />
        <Route path="/dashboard/help" element={<HelpPage/>}/>
    </>
)