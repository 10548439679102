import { makeAPIRequest } from "./makeRequest";

export const PrintExtraVehicleData = (veh: string, selectedFile: string, dealerId: number) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/ChromeDataPrint?veh='+veh+'&selectedFile='+selectedFile+'&dealerId='+dealerId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}