import { makeAPIRequest } from "./makeRequest";

const GetLotHistory = (reportType: string, id: number) => {
    let config: any;
    if (reportType === 'lotHistory') {
        config = {
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'LotLocationHistory/GetData?dealid=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (reportType === 'scannedVehicleNotes') {
        config = {
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'ScannedVehicleNotes/GetData?dealid=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (reportType === 'vehicleStatus') {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehicleStatus/GetData?dealid=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (reportType === 'Extra Vehicles') {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'ExtraVehicle/GetScannedVehicles?dealid=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    let res: any = makeAPIRequest(config);
    return res
}

export default GetLotHistory