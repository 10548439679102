import React, { useEffect, useState, useCallback } from "react";
import './styles.css'
import GetModal from "../api/getModal";
import { AlertsData } from './dashboard';

interface Props {
    onAlertChnage: AlertsData
}

const DmsConnectivity = ({onAlertChnage}: Props) => {
    const [dealerShipName, setDealerShipName] = useState("");
    const [companyID, setCompanyID] = useState('');
    const [enterprise, setEnterprise] = useState('');
    const [hostAddress, setHostAddress] = useState('');
    const [lotlocations, setLotLocations] = useState('');
    const [stockInCo, setStockInCo] = useState('');
    const [lotLocationOptionNumber, setLotLocationOptionNumber] = useState('');
    const [lotLocationOptionDescription, setLotLocationOptionDescription] = useState('');
    const [lastScannedOptionNumber, setLastScannedOptionNumber] = useState('');
    const [lastScannedOptionDescription, setLastScannedOptionDescription] = useState('');
    const [dealerCode, setDealerCode] = useState('');
    const [integrationUrl, setIntegrationUrl] = useState('');
    const [authUrl, setAuthUrl] = useState('');
    const [dealNo, setDealNo] = useState('');
    const [senderName, setSenderName] = useState('');
    const [destName, setDestName] = useState('');
    const [DmsType, setDmsType] = useState(0);
    const [downloadContent, setDownloadContent] = useState(false);
    const [DmsID, setDmsID] = useState(0);
    const [dealerShipData, setDealShipData] = useState({});
    let Did = sessionStorage.getItem('dealerId')

    const fetchData = useCallback(async () => {
        if (Did) {
            let res = await GetModal('GET', 'DealerShip/DMSCreate?dealerIdSel=' + Did + '-DMSConnectivity', '')
            setDmsID(res.dmsId);
            setDealShipData(res.dealship);
            setDealerShipName(res.dealship.dealerShipName);
            setCompanyID(res.companyID);
            setEnterprise(res.enterprise);
            setHostAddress(res.hostAddress)
            setLotLocations(res.lotlocations)
            setStockInCo(res.stockInCo)
            setLotLocationOptionNumber(res.lotLocationOptionNumber)
            setLotLocationOptionDescription(res.lotLocationOptionDescription)
            setLastScannedOptionNumber(res.lastScannedOptionNumber)
            setLastScannedOptionDescription(res.lastScannedOptionDescription)
            setDealerCode(res.dealerCode)
            setIntegrationUrl(res.integrationURLForDominion);
            setAuthUrl(res.authenticationURL);
            setDealNo(res.dealerNumberID);
            setSenderName(res.senderNameCode);
            setDestName(res.destinationNameCode);
            setDownloadContent(res.downloadCustomerVehicles);
            setDmsType(res.dmstype)
        }
    }, [Did]);

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    const sendData = async () => {
        let res;
        let data = {
            "dmsId": DmsID,
            "companyID": companyID,
            "enterprise": enterprise,
            "hostAddress": hostAddress,
            "lotlocations": lotlocations,
            "stockInCo": stockInCo,
            "dealship": dealerShipData,
            "dmstype": DmsType,
            "dealerCode": dealerCode,
            "lotLocationOptionNumber": lotLocationOptionNumber,
            "lotLocationOptionDescription": lotLocationOptionDescription,
            "lastScannedOptionNumber": lastScannedOptionNumber,
            "lastScannedOptionDescription": lastScannedOptionDescription,
            "integrationURLForDominion": integrationUrl,
            "dealerNumberID": dealNo,
            "senderNameCode": senderName,
            "destinationNameCode": destName,
            "authenticationURL": authUrl,
            "downloadCustomerVehicles": downloadContent,
            "file": null
        }
        if (DmsID === 0) {
            res = await GetModal('POST', 'DealerShip/DMSConnectivityPost?dealerIdSel=' + Did + '-DMSConnectivity', data)
            if(res === "Success") {
                sendResponse(0, 'DMS data created successfully', 'Success')
            } else {
                sendResponse(1, 'Failed to create the DMS Data', 'Failure')
            }
        } else {
            res = await GetModal('POST', 'DealerShip/DMSEdit?dealerIdSel=' + Did + '-DMSConnectivity', data);
            if(res === "Success") {
                sendResponse(0, 'DMS data updated successfully', 'Success')
            } else {
                sendResponse(1, 'Failed to update the DMS Data', 'Failure')
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handelRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDmsType(parseInt(e.target.value));
    }

    // if (DmsType === 0 && hostAddress === "") {
    //     setHostAddress("https://3pa.dmotorworks.com/pip-extract/inventoryvehicleext/extract")
    // } else {
    //     setHostAddress(hostAddress)
    // }

    return (
        <div>
            <div className="modalTop-body mt-3">
                <div className="grid grid-cols-2 gap-2 items-center">
                    <p className="sampleLabel">Dealership Name</p>
                    <input className="textInput" type="text" placeholder="Dealership Name" value={dealerShipName} readOnly />
                </div>
                <div className="grid grid-cols-2 gap-2 mt-2">
                    <p className="sampleLabel">Dms Type</p>
                    <div>
                        <input type="radio" id="cdk" name="dms" value={0} checked={DmsType === 0} onChange={handelRadioChange} /> &nbsp;
                        <label htmlFor="cdk" className="sampleLabel">CDK</label> &nbsp;&nbsp;
                        <input type='radio' id="html" name="dms" value={1} checked={DmsType === 1} onChange={handelRadioChange} />&nbsp;
                        <label htmlFor="html" className="sampleLabel">Dealer Track</label> &nbsp;&nbsp;
                        <input type='radio' id="html" name="dms" value={2} checked={DmsType === 2} onChange={handelRadioChange} />&nbsp;
                        <label htmlFor="html" className="sampleLabel">Dominion</label> &nbsp;&nbsp;
                        <input type='radio' id="html" name="dms" value={3} checked={DmsType === 3} onChange={handelRadioChange} />&nbsp;
                        <label htmlFor="html" className="sampleLabel">File Based</label> &nbsp;&nbsp;
                    </div>
                </div>
                <hr className="mt-2" />
            </div>
            <div>
                {DmsType === 0 || DmsType === 1 ? (
                    <div>
                        <div className="modal-body">
                            <div className="grid grid-cols-4 gap-2 mt-2 items-center justify-center">
                                <p className="sampleLabel">Company ID</p>
                                <input className="textInput" type="text" placeholder="Company Id" value={companyID} onChange={(e) => setCompanyID(e.target.value)} />
                                <p className="sampleLabel">Enterprise</p>
                                <input className="textInput" type="text" placeholder="Enterprise name" value={enterprise} onChange={(e) => setEnterprise(e.target.value)} />
                            </div>
                            <div className="grid grid-cols-2 gap-2 mt-2 items-center">
                                <p className="sampleLabel">Host Address</p>
                                <input className="textInput" type="url" placeholder="https://host123.subhost.com/testpage" value={hostAddress} onChange={(e) => setHostAddress(e.target.value)} />
                            </div>
                            <div className="flex justify-start items-center mt-2">
                                <input type="checkbox" checked={downloadContent} onChange={() => {setDownloadContent(!downloadContent)}} />&nbsp;&nbsp;
                                <p className="sampleLabel">Download Customer Vehicles</p>
                            </div>
                        </div>
                    </div>
                ) : DmsType === 2 ? (
                    <div>
                        <div className="modal-body">
                            <div className="flex justify-start items-center mt-2">
                                <input type="checkbox" />&nbsp;&nbsp;
                                <p className="sampleLabel">Download Customer Vehicles</p>
                            </div>
                            <div className="grid grid-cols-4 gap-2 mt-2 items-center justify-center">
                                <p className="sampleLabel">Integration URL</p>
                                <input className="textInput" type="text" placeholder="Company Id" value={integrationUrl} onChange={(e) => setCompanyID(e.target.value)} />
                                <p className="sampleLabel">Authentication URL</p>
                                <input className="textInput" type="text" placeholder="Enterprise name" value={authUrl} onChange={(e) => setEnterprise(e.target.value)} />
                            </div>
                            <div className="grid grid-cols-4 gap-2 mt-2 items-center">
                                <p className="sampleLabel">Dealer Number ID</p>
                                <input className="textInput" type="url" placeholder="https://host123.subhost.com/testpage" value={dealNo} onChange={(e) => setHostAddress(e.target.value)} />
                                <p className="sampleLabel">Sender Name Code</p>
                                <input className="textInput" type="url" placeholder="https://host123.subhost.com/testpage" value={senderName} onChange={(e) => setHostAddress(e.target.value)} />
                            </div>
                            <div className="grid grid-cols-4 gap-2 mt-2 items-center">
                                <p className="sampleLabel">Destination Name Code</p>
                                <input className="textInput" type="url" placeholder="https://host123.subhost.com/testpage" value={destName} onChange={(e) => setHostAddress(e.target.value)} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                    </div>
                )}
                <div className="common-modal-body">
                    <div className="grid grid-cols-4 gap-2 mt-2 items-center">
                        <p className="sampleLabel">Lot Locations</p>
                        <input className="textInput" type="text" placeholder="10.4654375 N, 72.48944 E" value={lotlocations} onChange={(e) => setLotLocations(e.target.value)} />
                        <p className="sampleLabel">Stock In Co.</p>
                        <input className="textInput" type="number" placeholder="15" value={stockInCo} onChange={(e) => setStockInCo(e.target.value)} />
                    </div>
                    <div className="grid grid-cols-4 gap-2 mt-2 items-center">
                        <p className="sampleLabel">Lot Location Option Number</p>
                        <input className="textInput" type="number" placeholder="54" value={lotLocationOptionNumber} onChange={(e) => setLotLocationOptionNumber(e.target.value)} />
                        <p className="sampleLabel">Lot Location Option Description</p>
                        <input className="textInput" type="text" placeholder="Lot location option Description" value={lotLocationOptionDescription} onChange={(e) => setLotLocationOptionDescription(e.target.value)} />
                    </div>
                    <div className="grid grid-cols-4 gap-2 mt-2 items-center">
                        <p className="sampleLabel">Last Scanned Option Number</p>
                        <input className="textInput" type="number" placeholder="48" value={lastScannedOptionNumber} onChange={(e) => setLastScannedOptionNumber(e.target.value)} />
                        <p className="sampleLabel">Last Scanned Option Description</p>
                        <input className="textInput" type="text" placeholder="Last Scanned Option Description" value={lastScannedOptionDescription} onChange={(e) => setLastScannedOptionDescription(e.target.value)} />
                    </div>
                    <div className="grid grid-cols-2 gap-2 mt-2 items-center">
                        <p className="sampleLabel">DealerCode:(6 characters only)</p>
                        <input className="textInput" type="number" placeholder="45" maxLength={6} value={dealerCode} onChange={(e) => setDealerCode(e.target.value)} />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="flex justify-around items-center mt-4">
                        <button className="button-6" onClick={sendData}>
                            {DmsID === 0 ? 'Create' : 'Save'}
                        </button>
                        {/* <button className="button-6">Cancel</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DmsConnectivity