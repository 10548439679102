import { makeAPIRequest } from "./makeRequest";

const GetHeaderType = (data: any, id: number, type: string) => {
    let config
    if (type === 'initial') {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/CheckInventoryStatus?dealerId='+ id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (type === 'getInventories') {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/CheckForAppConnect?dealerid='+ id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (type === 'setInventories') {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/SaveForPhysicalInventory?userNames=' + data,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (type === 'endInventory') {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/EndInventory?dealerID=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (type === 'getInventoryDate') {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/Lastdownload?dealer=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    let res = makeAPIRequest(config)
    return res
}

export default GetHeaderType