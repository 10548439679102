import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { useSearch } from '../context/searchContext';

const AnimatedSerach = () => {
    const [localSearchTerm, setLocalSearchTerm] = useState<string>('');
    const { setSearchTerm } = useSearch();

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLocalSearchTerm(value);
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if(localSearchTerm !== '') {
                setSearchTerm(localSearchTerm);
            } else {
                window.dispatchEvent(new CustomEvent('inventoryRefresh'));
            }
        }
    }

    return (
        <div className="flex items-center justify-center">
            <div className="relative flex items-center rounded-xl shadow-sm bg-white w-96 overflow-hidden">
                <input type="text" placeholder="Search ..." value={localSearchTerm} onKeyPress={handleKeyPress} onChange={handleSearchChange} className="w-full bg-transparent outline-none pl-6 pr-6 py-3 text-coal placeholder-coal" />
                <div className="absolute right-4 z-10 rounded-xl shadow-sm">
                    <Search className="text-coal" size={20} />
                </div>
            </div>
        </div>
    )
}

export default AnimatedSerach