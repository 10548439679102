import { makeAPIRequest } from "./makeRequest";

const DeleteOperationAPI = (data: any, endPoint: string) => {
    let config: any;
    config = {
        method: 'DELETE',
        url: process.env.REACT_APP_SERVER_URL + endPoint,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        data: data
    }

    let res = makeAPIRequest(config)
    return res;
}

export default DeleteOperationAPI