import { makeAPIRequest } from "./makeRequest";

export const TestQR = (userId: number, urlString: string) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/GetBarCodeURL?iDealerId=' + userId + '&strUrlTemplate=' + urlString,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export const clearAllQRScans = (userId: string) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/ClearAllScanCount?DealerId=' + userId,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}