import { makeAPIRequest } from "./makeRequest";

export const FeedbackAPI = (feedbackData: { comment: any; rating: any; userName: any; dealerId: any; followUp: any; }) => {

    let feedbackFormFromReact = {
        Comment: feedbackData.comment,
        Rating: feedbackData.rating,
        UserName: feedbackData.userName,
        DealerId: feedbackData.dealerId,
        FollowUp: feedbackData.followUp
    }

    let config = {
        method: 'POST',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/AddCustomerFeedback',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data: feedbackFormFromReact
    }

    let res = makeAPIRequest(config);
    return res
}