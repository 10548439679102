import React, { useState, useEffect, useCallback } from "react";
import { AlertsData } from "./dashboard";
import './styles.css';
import {
    ChangeActiveStatusOfUser,
    CheckActiveUsers,
    CheckCredentials
} from "../api/inventoryAPI";

import GetHeaderType from "../api/getHeaderType";

interface Props {
    onAlertChnage: AlertsData;
    onClose: () => void;
}

const InventorySelection: React.FC<Props> = ({ onAlertChnage, onClose }: Props) => {
    const [inventoryData, setInventoryData] = useState<string[]>([]);
    const [inventorySelected, setInventorySelected] = useState<string[]>([]);
    const [askToClear, setAskToClear] = useState<boolean>(false);
    const [scanningAgentsActive, setScanningAgentsActive] = useState<boolean>(false);
    const [overrideAgents, setOverrideAgents] = useState<boolean>(false);
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [errors, setErrors] = useState<string>('');

    const dealerId = sessionStorage.getItem('dealerId');

    // Fetch inventory data
    const fetchData = useCallback(async () => {
        if (!dealerId) return;

        const res = await GetHeaderType('', parseInt(dealerId), 'getInventories');
        if (res?.appConnect && res.appConnect !== "Disable" && res.userDevices?.length > 0) {
            const userNames = res.userDevices.map((item: { user: { userName: string } }) => item.user.userName);
            setInventoryData(userNames);
        } else {
            setAskToClear(true);
        }
    }, [dealerId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Handle checkbox changes
    const handleCheckChanged = (item: string) => {
        setInventorySelected(prevList =>
            prevList.includes(item)
                ? prevList.filter(user => user !== item)
                : [...prevList, item]
        );
    };

    // Clear scanned vehicles
    const handleClearScannedVehicles = async () => {
        if (!dealerId) return;

        const activeUsers = await CheckActiveUsers(dealerId);
        if (activeUsers > 0) {
            setScanningAgentsActive(true);
        } else {
            handleChangeActiveStatus(dealerId);
        }
    };

    // Change active status
    const handleChangeActiveStatus = async (dealerId: string) => {
        const result = await ChangeActiveStatusOfUser(dealerId);
        if (result === "false") {
            onAlertChnage (1, 'Error on clearing scans', 'Failure');
        } else {
            window.location.reload();
        }
    };

    // Check credentials
    const handleCheckCredentials = async () => {
        const { username, password } = credentials;
        if (!username || !password) {
            setErrors('Please enter both username & password!');
            return;
        }

        if (username !== sessionStorage.getItem('userName')) {
            setErrors('Incorrect Username please try again!');
            return;
        }

        if (!dealerId) return;

        const res = await CheckCredentials(username, password, dealerId);
        if (res === 'false') {
            setErrors('Incorrect Password please try again!');
        } else if (!res) {
            setErrors('Something went wrong please try again!');
        } else {
            handleChangeActiveStatus(dealerId);
        }
    };

    // Send selected inventory data
    const handleSendData = async () => {
        const res = await GetHeaderType(inventorySelected.join(";"), 0, 'setInventories');
        if (res === "\"Success\"") {
            onAlertChnage (0, 'Inventory started successfully', 'Success');
            window.location.reload();
        } else {
            onAlertChnage (1, 'Failed to start inventory', 'Failure');
        }
    };

    // Render functions
    const renderInventorySelection = () => (
        <div>
            <p className="text-graphite headerText">Please select users to enable physical inventory</p>
            {inventoryData.map((item, index) => (
                <div key={index}>
                    <input
                        type="checkbox"
                        checked={inventorySelected.includes(item)}
                        onChange={() => handleCheckChanged(item)}
                    />
                    <label className="pl-3 capitalize text-graphite sampleLabel">{item}</label>
                </div>
            ))}
            <div className="flex justify-around items-center mt-3">
                <button onClick={handleSendData} className="button-6">Apply</button>
            </div>
        </div>
    );

    const renderClearConfirmation = () => (
        <div>
            <p className="text-graphite headerText">Do you want to clear Scanned Vehicle?</p>
            <div className="flex justify-around items-center mt-3">
                <button className="button-6" onClick={handleClearScannedVehicles}>Ok</button>
                <button className="button-6" onClick={onClose}>Cancel</button>
            </div>
        </div>
    );

    const renderOverrideConfirmation = () => (
        <div>
            <p className="text-graphite headerText">Scanning agents are active would you like to override?</p>
            <div className="flex justify-around items-center mt-3">
                <button className="button-6" onClick={() => setOverrideAgents(true)}>Yes</button>
                <button className="button-6" onClick={onClose}>No</button>
            </div>
        </div>
    );

    const renderCredentialsForm = () => (
        <div>
            <div className="flex justify-between items-center mt-3">
                <p className="text-graphite headerText pr-5">UserName</p>
                <input
                    type="text"
                    placeholder="Enter userName"
                    onChange={(e) => setCredentials(prev => ({ ...prev, username: e.target.value }))}
                />
            </div>
            <div className="flex justify-between items-center mt-3">
                <p className="text-graphite headerText">Password</p>
                <input
                    type="password"
                    placeholder="Please enter the password"
                    onChange={(e) => setCredentials(prev => ({ ...prev, password: e.target.value }))}
                />
            </div>
            <div className="flex justify-around items-center mt-3">
                <button className="button-6" onClick={handleCheckCredentials}>Submit</button>
            </div>
            {errors && <p className="text-center text-errorRed">{errors}</p>}
        </div>
    );

    return (
        <div className="mt-2 justify-center flex">
            {askToClear
                ? scanningAgentsActive
                    ? overrideAgents
                        ? renderCredentialsForm()
                        : renderOverrideConfirmation()
                    : renderClearConfirmation()
                : renderInventorySelection()
            }
        </div>
    );
};

export default InventorySelection;