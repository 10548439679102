import { makeAPIRequest } from "./makeRequest";

const BeaconOps = (operation: string, reportType: string, id: number, data: any) => {
    let config
    if(reportType === "keys" && operation === "get") {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'Beacons/GetDataForKeys?dealerID=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (reportType === "vehicles" && operation === "get") {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'Beacons/GetDataForVehicles?dealerID='+ id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export default BeaconOps