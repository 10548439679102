import React from "react";
import './styles.css'
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const nav = useNavigate();
    return (
        <div className="errorBg">
            <div className="bottomBtn">
                <button className="button-86" onClick={() => nav('/')}>Home</button>
            </div>
        </div>
    )
}

export default NotFound