import { makeAPIRequest } from "./makeRequest";

const PostEditUserList = (data: any, op: any) => {
    let config;
    if (op === "POST") {
        config = {
            method: op,
            url: process.env.REACT_APP_SERVER_URL + 'User/GetPost?vehicle=' + data,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Type': 'text'
            },
        }
    }

    let res = makeAPIRequest(config)
    return res
}

export default PostEditUserList