import React from "react";
import { GridDataState } from "../utils/store";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import './styles.css';
import { PrintInvoice } from "../api/getInvoiceAPI";

const UserForms = () => {
    const initialGridData = useSelector((state: GridDataState) => state.gridData.gridData);
    const cleanedHtmlContent = initialGridData.replace(/<!DOCTYPE[^>]*>/g, '').replace(/<\/?html[^>]*>/g, '').replace(/<\/?body[^>]*>/g, '').replace(/<\/?head[^>]*>/g, '');
    let fileName = sessionStorage.getItem('printFileName');
    let Did = sessionStorage.getItem('dealerId');
    let vinNumber = sessionStorage.getItem('vinNumber');

    const handlePrint = async () => {
        let data = getCheckBoxValues();
        let tbData1 = getTextBoxValues('limitedWarranty1');
        let tbData2 = getTextBoxValues('limitedWarranty2');
        let tbData3 = getTextBoxValues('monthsCount');
        let tbData4 = getTextBoxValues('milesCount');
        let tbData5 = getTextBoxValues('email');
        let postString = data.checkBoxID1 + '_' + data.checkBoxID2 + '_' + data.checkBoxID3 + '_' + data.checkBoxID4 + '_' + data.checkBoxID5 + '_' + data.checkBoxID6 + '_' + data.checkBoxID7 + '_' + data.checkBoxID8 + '_' + tbData1 + '_' + tbData2 + '_' + tbData3 + '_' + tbData4 + '_' + tbData5 + '_';
        if (vinNumber && Did && postString && fileName) {
            let res = await PrintInvoice(vinNumber, Did, postString, fileName)
            if (res) {
                const blob = new Blob([res], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = url;
                document.body.appendChild(iframe); iframe.onload = function () {
                    iframe.contentWindow?.print();
                };
            }
        }
    }

    const getTextBoxValues = (tbName: string) => {
        let data = document.getElementById(tbName) as HTMLInputElement
        return data.value ?? ''
    }

    const getCheckBoxValues = () => {
        const tempCheckboxValues: { [key: string]: boolean } = {};

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(cleanedHtmlContent, 'text/html');
        const checkboxes = htmlDoc.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');

        checkboxes.forEach((checkbox) => {
            const checkboxData = checkbox as HTMLInputElement;
            const checkboxId = checkboxData.id;

            const getCheckedStatus = () => {
                let data = document.getElementById(checkboxId) as HTMLInputElement;
                return data?.checked ?? false;
            };

            const isChecked = getCheckedStatus();
            tempCheckboxValues[checkboxId] = isChecked;
        });
        return tempCheckboxValues
    }

    return (
        <div className="mt-2 flex justify-center items-center flex-col">
            <div className="flex justify-center m-2">
                <button className="button-6" onClick={handlePrint}> Print Form</button>
            </div>
            <div id="div2">{parse(cleanedHtmlContent)}</div>
        </div>
    )
}

export default UserForms