import React from "react";

const ContactUs = () => {
    return (
        <div className="w-3/4 mx-auto text-left text-base font-sans">
            <h1 className="text-2xl font-bold mb-4">Contact Us</h1>

            <blockquote className="pl-4">
                <p className="mb-4">If you have any questions about these Terms and Conditions, you can contact us:</p>
                <ul className="list-disc pl-8 space-y-2">
                    <li>
                        By email: <a href="mailto:support@scansolutions.com" className="text-blue-600 hover:text-blue-800 underline">
                            support@scansolutions.com
                        </a>
                    </li>
                    <li>
                        By visiting our website: <a href="https://scansolutions.com" className="text-blue-600 hover:text-blue-800 underline">
                            scansolutions.com
                        </a>
                    </li>
                    <li>By phone number: (866) 834-1194</li>
                    <li>By mail: 9700 Farrar Ct, Richmond, VA 23236</li>
                </ul>
            </blockquote>
        </div>
    )
}

export default ContactUs