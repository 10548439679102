import React from "react";
import './styles.css'
import SaveGridData from "../api/updateGrid";
import { useSelector } from "react-redux";
import { GridDataState, clearGridData, Store } from "../utils/store";
import { AlertsData } from './dashboard';

interface Props {
    onAlertChnage: AlertsData
}

const SaveGridModal = ({onAlertChnage}: Props) => {
    const gridData = useSelector((state: GridDataState) => state.gridData);

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    const saveData = async(option: string) => {
        if (option === 'dealerDefault') {
            let res = await SaveGridData(1, gridData.gridData)
            if(res === "Success") {
                sendResponse(0, 'Grid saved successfully', 'Success')
                Store.dispatch(clearGridData(gridData));
            } else {
                sendResponse(1, 'Failed to save the grid', 'Failure')
            }
        } else {
            let res = await SaveGridData(2, gridData.gridData)
            if(res === "Success") {
                sendResponse(0, 'Grid has been successfully saved for you', 'Success')
                Store.dispatch(clearGridData(gridData));
            } else {
                sendResponse(1, 'Failed save the grid', 'Failure')
            }
        }
    }
    return (
        <div>
            <div className="modal-body mt-3">
                <p className="headerText text-md text-center"> Would you like to save the <b><q>Grid</q> as a Dealer Default</b> or <b>Only for you</b> </p>
            </div>
            <div className="modal-footer">
                <div className="flex justify-around items-center mt-4">
                    <button className="button-6" onClick={() => {
                        saveData('dealerDefault')
                    }}>Save as dealer default</button>
                    <button className="button-6" onClick={() => {
                        saveData('meOnly')
                    }}>Only for me</button>
                </div>
            </div>
        </div>
    )
}

export default SaveGridModal