import { makeAPIRequest } from "./makeRequest";

const GridSettings = (userName: string) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'GridSettingsForReact/GetGridSettings?username=' + userName,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config)
    return res
}

export default GridSettings