import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Store } from './utils/store';
import AuthLayout from './layout/AuthLayout';
import MainLayout from './layout/mainLayout';
import { homeRoutes } from './routes/homeroutes';
import { authRoutes } from './routes/authroutes';
import TermsAndConditions from './components/termasAndConditions';
import ProtectedRoutes from './routes/protectedRoutes';
import AuthFailure from './pages/authFailure';
import NotFound from './pages/404';
import ForgotPassword from './pages/forgotPassword';
import AutoLogout from './utils/autoLogout';

function App() {
	return (
		<Provider store={Store}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<AuthLayout />}>
						{authRoutes}
					</Route>
					<Route element={<ProtectedRoutes />}>
						<Route path="/dashboard" element={<AutoLogout><MainLayout /></AutoLogout>}>
							{homeRoutes}
						</Route>
					</Route>
					<Route path="/termsAndConditions" element={<TermsAndConditions />} />
					<Route path="/unauthorised" element={<AuthFailure />} />
					<Route path="/forgotPassword" element={<ForgotPassword />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</Provider>
	);
}

export default App;