import { makeAPIRequest } from "./makeRequest";
let config;

export const GetAllFiles = () => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'LabelTemplates/Index',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export const UploadFile = (file: File) => {
    config = {
        method: 'POST',
        url: process.env.REACT_APP_SERVER_URL + 'LabelTemplates/UploadLabelsTemplates',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        data: {
            file: file
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export const DeleteFile = (fileName: string, filePath: string) => {
    config = {
        method: 'DELETE',
        url: process.env.REACT_APP_SERVER_URL + 'LabelTemplates/DeleteFile?fileName=' + fileName + '&filePath=' + filePath,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    }

    let res = makeAPIRequest(config);
    return res;
}
