import React from "react";
import { Route } from "react-router-dom";
import Login from "../pages/login";
import NotFound from "../pages/404";
import ContactUs from "../pages/contactUs";
import TermsAndConditions from "../components/termasAndConditions";

export const authRoutes = (
    <>
        <Route index element={<Login/>} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/contactus" element={<ContactUs />}/>
        <Route path="/TnC" element={<TermsAndConditions />} />
    </>
)