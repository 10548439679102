import React from "react";
import './styles.css';

const termsAndConditions = () => {
    return (
        <div className="w-3/4 mx-auto text-left text-base font-sans">
            <h1 className="text-2xl font-bold">Terms and Conditions</h1>

            <blockquote className="my-4">
                <p>Please read these terms and conditions carefully before using Our Service.</p>
            </blockquote>

            <section>
                <h2 className="text-xl font-bold">Interpretation and Definitions</h2>

                <blockquote className="my-4">
                    <h3 className="font-bold">Interpretation</h3>
                    <blockquote className="my-4">
                        <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                    </blockquote>

                    <h3 className="font-bold">Definitions</h3>
                    <blockquote className="my-4">
                        <p>For the purposes of these Terms and Conditions:</p>
                        <ul className="list-disc pl-8 space-y-2 my-4">
                            <li>Application means the software program provided by the Company downloaded by You on any electronic device, named Vehicle Wizard Web</li>
                            <li>Application Store means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.</li>
                            <li>Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
                            <li>Country refers to: Virginia, United States</li>
                            <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Scan Solutions LLC, 9700 Farrar Ct Richmond, VA 23236.</li>
                            <li>Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
                            <li>Service refers to the Application or the Website or both.</li>
                            <li>Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
                            <li>Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</li>
                            <li>Website refers to Vehicle Wizard Web, accessible from <a href="https://www.ssivehiclewiz.com/" className="text-blue-600 hover:text-blue-800">https://www.ssivehiclewiz.com/</a></li>
                            <li>"You" or "Your" means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
                        </ul>
                    </blockquote>
                </blockquote>
            </section>

            <section>
                <h2 className="text-xl font-bold">Acknowledgment</h2>
                <blockquote className="my-4">
                    <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service. Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service. By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service. You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                </blockquote>
            </section>

            <section>
                <h2 className="text-xl font-bold">Links to Other Websites</h2>
                <blockquote className="my-4">
                    <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company. The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services.</p>
                </blockquote>
            </section>

            <section>
                <h2 className="text-xl font-bold">Contact Us</h2>
                <blockquote className="my-4">
                    <p>If you have any questions about these Terms and Conditions, you can contact us:</p>
                    <ul className="list-disc pl-8 space-y-2 my-4">
                        <li>By email: <a href="mailto:support@scansolutions.com" className="text-blue-600 hover:text-blue-800">support@scansolutions.com</a></li>
                        <li>By visiting our website: <a href="https://scansolutions.com" className="text-blue-600 hover:text-blue-800">scansolutions.com</a></li>
                        <li>By phone number: (866) 834-1194</li>
                        <li>By mail: 9700 Farrar Ct, Richmond, VA 23236</li>
                    </ul>
                </blockquote>
            </section>
        </div>
    )
}

export default termsAndConditions