import React, { useState, useEffect, useCallback } from "react";
import Print from "../api/print";
import { GridDataState } from '../utils/store';
import { useSelector } from 'react-redux';
import './styles.css'
import * as JSPM from "jsprintmanager";
import { AlertsData } from "./dashboard";
import NotifyComponent from "../components/notifyComponent";
import { DownloadJSPMDriver } from "../utils/downloadDrivers";
import { PrintExtraVehicleData } from "../api/printVehicle";

interface Props {
    onAlertChnage: AlertsData;
    title: string
}

interface Template {
    name: string;
}

const PrintTemplateSelector = ({ onAlertChnage, title }: Props) => {
    const [templateName, setTemplateName] = useState<string>('');
    const [templateData, setTemplateData] = useState<Template[]>([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [error, setError] = useState<{ code?: number, message?: string }>({});
    const [executedOnce, setExecutedOnce] = useState<boolean>(false);
    let Did = sessionStorage.getItem('dealerId')
    const gridData = useSelector((state: GridDataState) => state.gridData);
    let vin = gridData.gridData;

    const fetchData = useCallback(async (type: string, fileName: string) => {
        if (Did && type === 'getTemplateList') {
            let res = await Print(type, parseInt(Did), '', '');
            let resFileData = res.split(';');
            if (resFileData.length > 1) {
                setTemplateData(resFileData.map((name: any) => ({ name })))
            } else {
                setTemplateName(resFileData[0]);
            }
        } else if ('getTemplateData') {
            const vins = Array.isArray(vin) ? vin : [vin];
            const data = await Promise.all(
                vins.map(async (element: string) => {
                    if (Did) {
                        return Print(type, parseInt(Did), element, fileName);
                    }
                })
            );

            return data;
        }
    }, [Did, vin])

    const jspmWSStatus = () => {
        if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
            return false;
        } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
            return true;
        } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
            return false;
        }
    }

    useEffect(() => {
        JSPM.JSPrintManager.license_url = process.env.REACT_APP_SERVER_URL + "JSPM/LicenseV7";
        JSPM.JSPrintManager.auto_reconnect = true;
        JSPM.JSPrintManager.start();
        setTimeout(() => {
            const isJSPMInstalled = jspmWSStatus();

            if (!isJSPMInstalled) {
                setError({ code: 3, message: 'No Driver found' });
                setShowNotification(true);
            } else {
                fetchData('getTemplateList', '');
            }
        }, 2000);
    }, [])

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    useEffect(() => {
        if (!executedOnce && templateName) {
            handlePrint();
            setExecutedOnce(true);
        }
    }, [templateName, executedOnce]);

    const handlePrint = async () => {
        if(title != "ExtraVehPrint") {
            let resData = await fetchData('getTemplateData', templateName);
            resData?.map(res => {
                const printJob = new JSPM.ClientPrintJob();
                printJob.clientPrinter = new JSPM.InstalledPrinter(res.defaultPrinter)
                printJob.printerCommands = res.zplFile;
                sendResponse(0, "Print job started", "Success");
                printJob.sendToClient();
            });
        } else {
            if(Did) {
                let resData = await PrintExtraVehicleData(JSON.stringify(vin), templateName, parseInt(Did))
                const printJob = new JSPM.ClientPrintJob();
                printJob.clientPrinter = new JSPM.InstalledPrinter(resData.defaultPrinter)
                printJob.printerCommands = resData.zplFile;
                sendResponse(0, "Print job started", "Success");
                printJob.sendToClient();
            }
        }
    }

    const handleOk = () => {
        setShowNotification(false);
        JSPM.JSPrintManager.stop();
        sendResponse(2, "Downloading jsPrintManager. After installing please refresh and try again", "Info");
        DownloadJSPMDriver();
    }

    const handleCancel = () => {
        setShowNotification(false);
        JSPM.JSPrintManager.stop();
        sendResponse(2, "Print cancelled as user didnt want to download the driver", "Info");
    }

    return (
        <div>
            {templateData.length < 1 ? (
                <div className="modalTop-body mt-3">
                    <p className="headerText text-center text-lg">Printing the file {templateName}</p>
                </div>
            ) : (
                <div>
                    <div className="modalTop-body mt-3">
                        <p className="headerText text-center text-lg">Select a file to print</p>
                    </div>
                    <div className="p-2 flex justify-center items-center modal-body">
                        <div className="grid grid-cols-2 gap-2">
                            {templateData.map(template => (
                                <div key={template.name} className="items-center">
                                    <input
                                        type="radio"
                                        checked={templateName === template.name}
                                        value={template.name}
                                        onChange={(e) => setTemplateName(e.target.value)}
                                    />
                                    <label className="sampleLabel pl-2">{template.name}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="flex justify-around items-center mt-4">
                            <button className="button-6" onClick={handlePrint}>Print</button>
                        </div>
                    </div>
                </div>
            )}
            {error?.code === 3 ? (
                <NotifyComponent title="Printer Driver not found" message='Printer driver not found!.Do you want to download the driver ?' onOk={handleOk} onCancel={handleCancel} show={showNotification} />
            ) : null}
        </div>
    )
}

export default PrintTemplateSelector