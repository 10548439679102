import { makeAPIRequest } from "./makeRequest";

const GetModal = ( op: any, input: any, data: any) => {
    let config;
    if(op === 'GET') {
        config = {
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + input,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (op === "POST") {
        config = {
            method: 'post',
            url: process.env.REACT_APP_SERVER_URL + input,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: data
        }
    }

    try {
        let res = makeAPIRequest(config);
        return res
    } catch (err) {
        console.log(err)
    }
}

export default GetModal