import { makeAPIRequest } from "./makeRequest";

const postReportData = async (reportType: string, data: any) => {
    let config;
    if (reportType === "All Vehicles") {
        config = {
            method: 'POST',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/UpdateVehicles',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: data
        }
    } else if (reportType === "other") {
        config = {
            method: 'PUT',
            url: process.env.REACT_APP_SERVER_URL + 'Vehicle/GetEdit',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: data
        }
    } else if (reportType === "addScannedVehicleNotes") {
        config = {
            method: 'POST',
            url: process.env.REACT_APP_SERVER_URL + 'ScannedVehicleNotes/GetPost',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: data
        }
    } else if (reportType === 'vehicleStatusChanges') {
        config = {
            method: 'PUT',
            url: process.env.REACT_APP_SERVER_URL + 'VehicleStatus/UpdateData',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: data
        } 
    } else if (reportType === 'scannedNotesChanged') {
        config = {
            method: 'PUT',
            url: process.env.REACT_APP_SERVER_URL + 'ScannedVehicleNotes/GetEdit',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: data
        }
    } else if (reportType === 'userListChange') {
        config = {
            method: 'PUT',
            url: process.env.REACT_APP_SERVER_URL + 'User/GetEditForReact',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: data
        }
    } else if (reportType === 'vehicleStatusAdd') {
        config = {
            method: 'PUT',
            url: process.env.REACT_APP_SERVER_URL + 'VehicleStatus/AddData',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: data
        }
    } else if (reportType === "extraVehicleAdd") {
        config = {
            method: 'POST',
            url: process.env.REACT_APP_SERVER_URL + 'ExtraVehicle/GetPost',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'aaplication/json'
            },
            data: data
        }
    } else if (reportType === "setScanValue") {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/VinNumber?Vin=' + data.vin + '&DealerId=' + data.id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'aaplication/json'
            }
        }
    } else if (reportType === "beaconsVehiclesReportEdited" || reportType === "beaconsKeysReportEditied") {
        config = {
            method: 'PUT',
            url: process.env.REACT_APP_SERVER_URL + 'Beacons/RegisterForVehicle',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'aaplication/json'
            },
            data: data
        }
    } else if (reportType === "beaconsKeysReportEdited") {
        config = {
            method: 'PUT',
            url: process.env.REACT_APP_SERVER_URL + 'Beacons/RegisterForKeys',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'aaplication/json'
            },
            data: data
        }
    }

    let res = await makeAPIRequest(config)
    return res
}

export default postReportData