import { makeAPIRequest } from "./makeRequest"

export const searchAPI = (report: string, searchItem: string) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/SearchFilter?report=' + report + '&text=' + searchItem,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}