import { useState } from "react";
const secure: boolean = true;
const sameSite: string = 'Strict'
const fullUrl = process.env.REACT_APP_URL
let domain: string = '.ssivehiclewiz.com'
if(fullUrl) {
    let hostname = new URL(fullUrl).hostname;
    domain = hostname.startsWith('WWW.') ? hostname.slice(4) : hostname;
}

interface AuthState {
    userName: string;
    accessToken: string;
    role: string;
    dealerId: string;
    dealerCode: string;
}

const getCookie = (name: string) => {
    const cookie = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${name}=`));

    return cookie?.split('=')[1];
}

const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value}; domain=${domain}; ${secure ? 'secure;' : ''} SameSite=${sameSite}`;
}

export default function useAuth() {
    const [auth, setAuth] = useState<AuthState>({} as AuthState);

    const logIn = (auth: AuthState) => {
        setAuth(auth);
        setCookie('userName', auth.userName);
        setCookie('accessToken', auth.accessToken);
        setCookie('role', auth.role);
        setCookie('dealerId', auth.dealerId);
        setCookie('dealerCode', auth.dealerCode);
    }

    const deleteCookie = (name: string) => {
        document.cookie = `${name} =; expires= Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/; ${secure ? 'secure;' : ''} SameSite=${sameSite}`;
    }

    const logOut = () => {
        setAuth({} as AuthState);
        deleteCookie("userName");
        deleteCookie("accessToken");
        deleteCookie("role");
        deleteCookie("dealerId");
        deleteCookie("dealerCode");
        localStorage.clear();
        sessionStorage.clear();
    }

    return {
        auth,
        logIn,
        logOut
    }
}