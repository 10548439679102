import React, { useState } from "react";
import './styles.css';
import ForgotPasswordAPI from "../api/forgotPass";
import { useNavigate } from "react-router-dom";
import Alert from "../components/alert";

const ForgotPassword = () => {
    const [password, setPassword] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    let [errorCode, setErrorCode] = useState<number>();
    let [errorMessage, setErrorMessage] = useState<string>('');
    const nav = useNavigate();

    const handleGetUser = async () => {
        let res: any = await ForgotPasswordAPI(username)
        console.log(res)
    }

    const handleGoHome = () => {
        nav('/');
    }

    return (
        <div className="authFailureBG h-screen flex flex-col items-center justify-center">
            <div className="bgShadow flex flex-col justify-center p-5 w-[40%] items-center">
                <p className="headerText text-lg mb-1">Locked out of your account?</p>
                <p className="sampleLabel text-center max-w-md"> Don't let it put the brakes on your journey. Simply provide your registered email below, and we'll send you a link to reset your password and get you back in the driver's seat.</p>
                <div className="mt-2 mb-2">
                    <input type="text" className="textInput max-w-md" placeholder="username / Email" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                </div>
                <div className="flex justify-between items-center">
                    <button className="button-56" onClick={handleGetUser}>Submit</button>
                    <button className="button-56" onClick={handleGoHome}>Cancel</button>
                </div>
            </div>
            {errorCode === 1 || errorCode === 0 ? (
                <div className="absolute top-0 right-0">
                    <Alert code={errorCode} message={errorMessage} />
                </div>
            ) : null}
        </div>
    )
}

export default ForgotPassword