import React from "react";
import './styles.css'
import { AlertsData } from './dashboard';
import SaveGridData from "../api/updateGrid";

interface Props {
    onAlertChnage: AlertsData
}

const ClearGrid = ({onAlertChnage}: Props) => {

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    const clearData = async (option: string) => {
        if(option === 'clearDealerDefault') {
            let res = await SaveGridData(3, '')
            if(res === "Success") {
                sendResponse(0, 'Grid cleared successfully', 'Success')
            } else if (res === "Settings not available!") {
                sendResponse(2, res, 'Info')
            } else {
                sendResponse(1, 'Failure in clearing the grid', 'Failure')
            }
        } else {
            let res = await SaveGridData(4, '')
            if(res === "Success") {
                sendResponse(0, 'Grid cleared successfully', 'Success')
            } else if (res === "Settings not available!") {
                sendResponse(2, res, 'Info')
            } else {
                sendResponse(1, 'Failure in clearing the grid', 'Failure')
            }
        }
    }

    return (
        <div>
            <div className="modal-body mt-3">
                <p className="headerText text-md text-center"> Would you like to save the <b><q>Grid</q> as a Dealer Default</b> or <b>Only for you</b> </p>
            </div>
            <div className="modal-footer">
                <div className="flex justify-around items-center mt-4">
                    <button className="button-6" onClick={() => {
                        clearData('clearDealerDefault')
                    }}>Clear dealer default</button>
                    <button className="button-6" onClick={() => {
                        clearData('clearMeOnly')
                    }}>Clear my own</button>
                </div>
            </div>
        </div>
    )
}

export default ClearGrid