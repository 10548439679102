import { makeAPIRequest } from "./makeRequest";

let config: object

export const GETAccessoryList = (userId: number) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehicleAccessory/GetAccessories?dealerid=' + userId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const AddAccessories = (bodyData: { name: string; description: string; accessoryPrice: Number; type: string }) => {
    console.log(bodyData)
    config = {
        method: 'POST',
        url: process.env.REACT_APP_SERVER_URL + 'VehicleAccessory/AddAccessories?Name=' + bodyData.name + '&Description=' + bodyData.description + '&AccessoryPrice=' + bodyData.accessoryPrice + '&Type=' + bodyData.type,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const UpdateAccessories = (bodyData: { id: number; name: string; description: string; accessoryPrice: Number; type: string }) => {
    config = {
        method: 'PUT',
        url: process.env.REACT_APP_SERVER_URL + 'VehicleAccessory/UpdateAccessories?ID=' + bodyData.id + '&Name=' + bodyData.name + '&Description=' + bodyData.description + '&AccessoryPrice=' + bodyData.accessoryPrice + '&Type=' + bodyData.type,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const DeleteAccessories = (recID: number) => {
    config = {
        method: 'DELETE',
        url: process.env.REACT_APP_SERVER_URL + 'VehicleAccessory/DeleteAccessoryRecords?recordID=' + recID,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config)
    return res
}

export const GetAccessoriesFrom = (accessoryList: string, vin: string, stock: string, model: string, freight: number, msrp: string, formName: string) => {
    config = {
        method: 'GET',
        responseType: 'blob',
        url: process.env.REACT_APP_SERVER_URL + 'VehicleAccessory/GetForm?accessories=' + accessoryList + '&vin=' + vin + '&stock=' + stock + '&model=' + model + '&freight=' + freight + '&msrp=' + msrp + '&fileName=' + formName,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const GETMSRPApi = (vinNumber: string) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehicleAccessory/GetMSRP?vin=' + vinNumber,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}