import React, { createContext, useState, ReactNode } from "react";

interface ReportTypeContextValue {
    reportType: string,
}

interface ReportTypeProviderProps {
    children: ReactNode;
    reportType: string
}

export const ReportTypeContext = createContext<ReportTypeContextValue | undefined>(undefined);

export const ReportTypeProvider: React.FC<ReportTypeProviderProps> = ({ children, reportType }) => {

    const value: ReportTypeContextValue = {
        reportType,
    };
    return <ReportTypeContext.Provider value={value}>{children}</ReportTypeContext.Provider>
}