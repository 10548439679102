import React from "react";

interface NotifyProps {
    title: string;
    message: string;
    onOk: () => void;
    onCancel: () => void;
    show: boolean;
}

const NotifyComponent = ({ title, message, onOk, onCancel, show }: NotifyProps) => {
    const handleOk = () => {
        onOk();
    }

    const handleCancel = () => {
        onCancel();
    }

    if(!show) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6">
                <h2 className="text-lg font-bold mb-4 text-graphite">{title}</h2>
                <p className="text-gray-700 mb-6">{message}</p>
                <div className="flex justify-end">
                <button
                        className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
                        onClick={handleOk}
                    >
                        Yes
                    </button>
                    <button
                        className="bg-red-500 text-white py-2 px-4 rounded-md mr-2 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                        onClick={handleCancel}
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NotifyComponent;