import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import PasswordChecklist from 'react-password-checklist';
import './styles.css'
import PasswordReset from '../api/passwordCheck';
import Alert from '../components/alert';
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
    const [oldPass, setOldPass] = useState<string>('');
    const [newPass, setNewPass] = useState<string>('');
    const [confirmPass, setConfirmPass] = useState<string>('');
    const [showPass1, setShowPass1] = useState<boolean>(false);
    const [showPass2, setShowPass2] = useState<boolean>(false);
    const [showPass3, setShowPass3] = useState<boolean>(false);
    const [showChecklist, setShowChecklist] = useState(false);
    const [showChecklistMatch, setShowChecklistMatch] = useState(false);
    const [errCode, setErrCode] = useState<number>();
    const [errMsg, setErrMsg] = useState<string>('');
    const [status, setStatus] = useState<boolean>(false);
    const nav = useNavigate();

    const handleSetMatchPassword = async (state: any) => {
        if (newPass === confirmPass) {
            let result = await PasswordReset('check', oldPass)
            if (result === 'true') {
                result = await PasswordReset('update', newPass)
                if (result === "true") {
                    setErrCode(0)
                    setErrMsg("Password updated successfully")
                    setStatus(true)
                } else {
                    setErrCode(1);
                    setErrMsg("Failure in updating the password")
                }
            } else {
                setErrCode(1);
                setErrMsg("The old password dosent exist in our system")
            }
        } else {
            setErrCode(1);
            setErrMsg("The confirm password field must be the same as the new password field")
        }
    }

    const handleCancel = () => {
        nav(-1);
    }

    return (
        <div className='fixed inset-0 flex flex-col justify-center items-center'>
            <div className='flex flex-col justify-center items-center'>
                <p className='text-3xl headerText'>Change password</p>
                <p className='headerText mt-5 text-lg'>Shift gears in your digital safety lane with a new password tune-up! 🛠️</p>
            </div>
            <div className='mt-3 shadow p-3'>
                <div className='grid grid-cols-3 gap-4 mt-2 items-center'>
                    <label className='sampleLabel'>Old Password</label>
                    <div className="flex items-center">
                        <input type={showPass1 ? "text" : "password"}
                            value={oldPass}
                            placeholder='Old password'
                            required
                            className='w-100 textInput'
                            onChange={(e) => {
                                setOldPass(e.target.value)
                            }} /> &nbsp;&nbsp;
                        <label className='text-red-700 text-2xl'>*</label>
                    </div>
                    <FontAwesomeIcon icon={showPass1 ? faEyeSlash : faEye} size='sm' onClick={() => { setShowPass1(!showPass1) }} />
                </div>
                <div className='grid grid-cols-3 gap-4 mt-2 items-center'>
                    <label className='sampleLabel'>New Password</label>
                    <div className="flex items-center">
                        <input type={showPass2 ? "text" : "password"}
                            value={newPass}
                            placeholder='New password'
                            required
                            className='w-100 textInput'
                            onFocus={() => setShowChecklist(true)}
                            onBlur={() => setShowChecklist(false)}
                            onChange={(e) => {
                                setNewPass(e.target.value)
                            }} /> &nbsp;&nbsp;
                        <label className='text-red-700 text-2xl'>*</label>
                    </div>
                    <FontAwesomeIcon icon={showPass2 ? faEyeSlash : faEye} size='sm' onClick={() => { setShowPass2(!showPass2) }} />
                </div>
                <div className='grid grid-cols-3 gap-4 mt-2 items-center'>
                    <label className='sampleLabel'>Confirm Password</label>
                    <div className="flex items-center">
                        <input type={showPass3 ? "text" : "password"}
                            value={confirmPass}
                            placeholder='Confirm password'
                            required
                            className='w-100 textInput'
                            onFocus={() => setShowChecklistMatch(true)}
                            onBlur={() => setShowChecklistMatch(false)}
                            onChange={(e) => {
                                setConfirmPass(e.target.value);
                            }} /> &nbsp;&nbsp;
                        <label className='text-red-700 text-2xl'>*</label>
                    </div>
                    <FontAwesomeIcon icon={showPass3 ? faEyeSlash : faEye} size='sm' onClick={() => { setShowPass3(!showPass3) }} />
                </div>
                {showChecklist && (
                    <div className="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-96">
                        <div className="p-6">
                            <h5 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                Password Check
                            </h5>
                            <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "maxLength", "lowercase", "letter"]}
                                minLength={8}
                                maxLength={20}
                                value={newPass}
                                style={{ fontFamily: 'Montaga', fontSize: '12px' }}
                                iconSize={12}
                            />
                        </div>
                    </div>
                )}
                {showChecklistMatch && (
                    <div className="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-96">
                        <div className="p-6">
                            <h5 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                                Password Check
                            </h5>
                            <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "maxLength", "lowercase", "letter", "match"]}
                                minLength={8}
                                maxLength={20}
                                value={newPass}
                                valueAgain={confirmPass}
                                style={{ fontFamily: 'Montaga', fontSize: '12px' }}
                                iconSize={12}
                            />
                        </div>
                    </div>
                )}
                <div className='flex justify-around items-center mt-2'>
                    {status ? (
                        <div>
                            <button className="button-6" onClick={handleCancel}>Retrun to Dashboard</button>
                        </div>
                    ) : (
                        <div>
                            <button className="button-6" onClick={handleSetMatchPassword}>Submit</button>
                            <button className="button-6" onClick={handleCancel}>Cancel</button>
                        </div>
                    )}
                </div>
            </div>
            {errCode === 1 || errCode === 0 ? (
                <div className="absolute top-0 right-0">
                    <Alert code={errCode} message={errMsg} />
                </div>
            ) : null}
        </div>
    )
}

export default ChangePassword