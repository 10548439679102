import React, { useState, useEffect, useCallback } from "react";
import GetModal from "../api/getModal";
import './styles.css';

const RecentDownloadsModal = () => {
    let Did = sessionStorage.getItem('dealerId');
    const [tableValues, setTableValues] = useState([]);
    const [downloadStatus, setDownloadStatus] = useState<boolean>(false);

    const fetchData = useCallback(async () => {
        if (Did) {
            let res = await GetModal('GET', 'DealerShip/DMSCreate?dealerIdSel=' + Did + '-RecentDownloads', '')
            if (res) {
                setDownloadStatus(true);
                setTableValues(res);
            } else {
                setDownloadStatus(false);
            }
        }
    }, [Did])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    return (
        <div>
            {downloadStatus ? (
                <table className="min-w-full bg-white border border-bermuda">
                    <thead className="text-gray-700">
                        <tr>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">Download ID</th>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">Start Date Time</th>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">End Date Time</th>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">Status</th>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">Vehicles Added</th>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">Vehicles Removed</th>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">Vehicles Updated</th>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">Message</th>
                            <th className="py-2 px-4 border sampleLabel border-bermuda">Reference Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableValues.map((item: any, index) => (
                            <tr key={index} className="border-b hover:bg-gray-100">
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{item.downloadID}</td>
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{new Date(item.startDateTime).toLocaleString()}</td>
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{new Date(item.endDateTime).toLocaleString()}</td>
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{item.status}</td>
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{item.vehiclesAdded}</td>
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{item.vehiclesRemoved}</td>
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{item.vehiclesUpdated}</td>
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{item.message}</td>
                                <td className="py-2 px-4 sampleLabel text-graphite text-center border border-bermuda">{item.referenceType}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : <p>No Recent Downloads Found</p>}
        </div>
    )
}

export default RecentDownloadsModal