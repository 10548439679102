import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { GetAllFiles, UploadFile, DeleteFile } from "../api/filesAPI";
import './styles.css';
import Alert from "../components/alert";

interface FileData {
    fileName: string;
    filePath: string;
}

interface ErrorState {
    code: number;
    message: string;
}

const TemplateList = () => {
    const [fileData, setFileData] = useState<FileData[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState<ErrorState>();

    useEffect(() => {
        const fetchData = async () => {
            let res: FileData[] = await GetAllFiles();
            setFileData(res);
        };
        fetchData();
    }, []);

    const handleDownload = async (name: string, path: string) => {
        let url = process.env.REACT_APP_SERVER_URL + 'LabelTemplates/DownloadFile?fileName=' + name + '&filePath=' + path
        window.location.href = url
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setSelectedFile(file);
            uploadFile(file)
        }
    };

    const handleFileUpload = async () => {
        inputRef.current?.click();
    }

    const uploadFile = async (file: File) => {
        try {
            let res = await UploadFile(file);
            if (res === "Success") {
                window.location.reload();
            }
        } catch (error) {
            setError({ code: 1, message: 'Error uploading file' });
        }
    }

    const handleFileDelete = async (name: string, path: string) => {
        try {
            let res = await DeleteFile(name, path);
            if (res === "Success") {
                window.location.reload();
            }
        } catch (error) {
            setError({ code: 1, message: 'Error deleting file' });
        }
    }

    const handleGoBack = () => {
        window.history.back()
    }

    return (
        <div>
            <div className="mt-5">
                <h1 className="headerText text-center text-2xl">Label Templates</h1>
            </div>
            <div className="w-full flex justify-center">
                <table className="w-[70%] table-auto sm:table-fixed border-collapse mt-5 mb-5">
                    <thead>
                        <tr className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
                            <th className="px-4 py-3 sm:w-1/3 border-r border-white">File Name</th>
                            <th className="px-4 py-3 sm:w-1/3 border-r border-white">
                                <div className="flex justify-center items-center">
                                    Download&nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faDownload} />
                                </div>
                            </th>
                            <th className="px-4 py-3 sm:w-1/3 border-r border-white">
                                <div className="flex justify-center items-center">
                                    Delete&nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fileData.map((item, index) => (
                            <tr
                                key={index}
                                className={`border-b ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} hover:bg-gray-200`}
                            >
                                <td className="px-4 py-3 truncate border-r border-gray-300">{item.fileName}</td>
                                <td className="px-4 py-3 truncate border-r border-gray-300">
                                    <div className="flex justify-center">
                                        <FontAwesomeIcon icon={faDownload} onClick={() => {
                                            handleDownload(item.fileName, item.filePath)
                                        }} />
                                    </div>
                                </td>
                                <td className="px-4 py-3 truncate border-r border-gray-300">
                                    <div className="flex justify-center">
                                        <FontAwesomeIcon icon={faTrashCan} onClick={() => {
                                            handleFileDelete(item.fileName, item.filePath)
                                        }} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-center">
                <div className="flex justify-between items-center w-[50%]">
                    <div className="pl-5">
                        <input type="file" ref={inputRef} id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} />
                        <label htmlFor="fileInput">
                            <button className="button-6" onClick={handleFileUpload}>Upload File</button>
                        </label>
                    </div>
                    <div className="pr-5">
                        <button className="button-6" onClick={handleGoBack}>Return to Dashboard</button>
                    </div>
                </div>
            </div>
            {error ? <Alert code={error.code} message={error.message} /> : null}
        </div>
    )
}

export default TemplateList