import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./layoutStyles.css";
import './style.css'
import CookieAcceptance from "../utils/cookieAcceptance";
import { GetHtmlFiles } from "../api/getHtmlFile";
import parse from 'html-react-parser';
import Logo from "../assets/Logo1.png"
import footerLogo from '../assets/footerLogo.jpg';

const AuthLayout = () => {
    const location = useLocation();
    const isContactUsPage = location.pathname === '/contactus';
    const isRootPath = location.pathname === '/';
    const [htmlContent, setHtmlContent] = useState(null);

    useEffect(() => {
        const getHtmlData = async () => {
            let data = await GetHtmlFiles()
            let cleanedUpData = data.replace(/<!DOCTYPE[^>]*>/g, '').replace(/<\/?html[^>]*>/g, '').replace(/<\/?body[^>]*>/g, '').replace(/<\/?head[^>]*>/g, '');
            setHtmlContent(cleanedUpData)
        }

        getHtmlData();
    }, [])

    useEffect(() => {
		if (htmlContent) {
			const ensureJQuery = new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = "https://code.jquery.com/jquery-1.11.3.min.js";
                script.onload = resolve;
                script.onerror = () => reject(new Error("Failed to load jQuery"));
                document.head.appendChild(script);
			});
			ensureJQuery
            .then(() => {
                const container = document.createElement("div");
                container.innerHTML = htmlContent;
                const scriptTags = container.querySelectorAll("script");
                scriptTags.forEach((script) => {
                    const newScript = document.createElement("script");
                    if (script.src) {
                        newScript.src = script.src; // Load external scripts
                    } else {
                        newScript.textContent = script.textContent; // Inline scripts
                    }
                    document.body.appendChild(newScript);
                    document.body.removeChild(newScript); // Cleanup after execution
                });
            })
            .catch((error) => console.error("Error ensuring jQuery:", error));
		}
	}, [htmlContent])

    const renderHeader = () => (
        <header className="pr-10 pl-10 headerImageContainer">
            <div className="flex justify-between items-center">
                <img src={Logo} alt="Vww_Logo" className="w-100 h-16 cursor-pointer" />
                <div className="flex-grow"></div>
                {isContactUsPage ? (
                    <a href="/" className="customeLinkBtn text-sm md:text-base">
                        Login
                    </a>
                ) : (
                    <div className="contactContainer flex items-center">
                        <a href="/contactus" className="customLinkBtn text-sm md:text-base">
                            Contact US
                        </a>
                    </div>
                )}
            </div>
        </header>
    );

    if (isRootPath) {
        return (
            <div className="main flex flex-col justify-between min-h-screen">
                {renderHeader()}
                <main>
                    <div className="mb-auto flex flex-row">
                        <div className="w-[50%]">
                            <Outlet />
                        </div>
                        {htmlContent && (
                            <div className="w-[50%] py-15 px-2 pt-8 h-fit">
                                <div className="p-2">{parse(htmlContent)}</div>
                            </div>
                        )}
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="w-[30%] ">
                            <img src={footerLogo} alt="Vww_Logo_Footer" />
                            <p className="text-center">Scan Solutions &copy; 2024</p>
                        </div>
                    </div>
                </main>
                <footer className="w-full h-auto bg-gray-200 py-2 footer">
                    <CookieAcceptance />
                </footer>
            </div>
        )
    }

    return (
        <div className="main flex flex-col justify-between min-h-screen">
            {renderHeader()}
            <main className="mb-auto">
                <div className="mx-auto h-full">
                    <Outlet />
                </div>
            </main>
            <footer className="w-full h-auto bg-gray-200 py-2 footer">
                <CookieAcceptance />
            </footer>
        </div>
    );
}

export default AuthLayout